import React from 'react'
import {Link} from 'gatsby'

const ServiceSidebar = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link to="/service-details" className="active">
                        Website & Mobile App Development
                    </Link>
                </li>
                <li>
                    <Link to="/service-details">
                        Artificial Intellegence
                    </Link>
                </li>
                <li>
                    <Link to="/service-details">
                        Data Warehouse
                    </Link>
                </li>
                <li>
                    <Link to="/service-details">
                        Design Solutions
                    </Link>
                </li>
                <li>
                    <Link to="/service-details">
                        Database Scalability
                    </Link>
                </li>
            </ul>

            <div className="download-file">
                <h3>Brochures</h3>

                <ul>
                    <li>
                        <Link to="#">
                            PDF Download <i className='bx bxs-file-pdf'></i>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            Services Details.txt <i className='bx bxs-file-txt'></i>
                        </Link>
                    </li>
                </ul>
            </div>

            <div className="services-contact-info">
                <h3>Contact Info</h3>
                
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Phone:</span>
                        <a href="tel:+84903276867">(+84)90 327 6867</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
                        Hanoi, Vietnam                       
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-envelope'></i>
                        </div>
                        <span>Email:</span>
                        <a href="mailto:contact@gritbros.com">contact@gritbros.com</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ServiceSidebar